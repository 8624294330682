.ImageGrid {
    display: grid;
    gap: var(--spacing-small-half);
    grid-template-columns: repeat(auto-fit,minmax(var(--card-min-width),1fr));
}

.Image {
    cursor: pointer;
    object-fit: contain;
}

.Header {
    padding-bottom: var(--spacing-small);

    p {
        color: var(--colour-text-primary);
    }
    
    h1 {
        color: var(--colour-text-accent-primary);
    }

    [data-align='left'] & {
        text-align: start !important;
    }
    [data-align='right'] & {
        text-align: end !important;
    }
    [data-align='center'] & {
        text-align: center !important;
    }
}