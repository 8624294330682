// Note: remove duplication between this and topic block lists

.ContentBlockList {

    > header {
        width: 100%;
        padding-left: 0;
        > h1 { 
            margin-left: 0; 

        }
        > * { max-width: 100%; }
        &:empty { display: none; }
        
        [data-site='magazines'] & {
            display: flex;
            justify-content: center;
            > h1 {
                color: var(--colour-text-secondary);
            }
        }
    }

    > ul {
        justify-content: stretch;
        align-items: stretch;
        margin: 0;
        padding: 0;
        > li {
            list-style-type: none;
        }
    }

    &[data-type='Compact'],
    &[data-type='Light'],
    &[data-type='Slideshow'],
    &[data-type='Testimonials'] {
        padding: var(--spacing-medium) var(--page-padding-horizontal);
        &:has([data-number='1']) {
            [data-site='events'] & {
                padding: 0;
            }
        }
    }
   
    &[data-type='Compact'] {
        > p {
            color: var(--colour-text-primary);
        }
    }

    &[data-type='Dropdowns'] {
        background: var(--colour-background-primary);
        > header {
            > :last-child {
                margin-bottom: 0;
                padding-bottom: 0;
            }
            margin-bottom: var(--spacing-small);
            > h1 {
                color: var(--colour-text-accent-primary);
            }
            > h2 {
                color: var(--colour-text-accent-secondary);
            }
            > p {
                color: var(--colour-text-primary);
            }
        }
    }
    &[data-type='Icons'] {
        [data-site='main'] & {
            display: flex;
            flex-direction: column;
            position: relative;
            padding-bottom: var(--spacing-medium);
            background: var(--colour-background-primary);
            &::before {
                content: '';
                position: absolute;
                display: block;
                top: var(--spacing-small);
                bottom: var(--spacing-small);
                left: var(--page-padding-horizontal);
                right: var(--page-padding-horizontal);
                background: var(--colour-background-secondary);
            }
            > header {
                margin-bottom: 0 !important;
                padding: var(--spacing-small) var(--spacing-medium) 0 var(--spacing-medium);
                display: flex;
                flex-direction: column;
                align-items: center;
                > h1 + p {
                    margin-top: 0;
                    font-size: var(--font-size-large);
                }
            }
            > ul {
                align-self: center;
                margin-top: 0 !important;
                padding: 0 var(--spacing-small)!important;
                display: grid;
                width: 100%;
                @media( min-width: 0px ) {
                    grid-template-columns: 1fr;
                }
                @media( min-width: 800px ) {
                    grid-template-columns: repeat(auto-fit,minmax(350px,1fr));
                }
                gap: 0;
            }
        }
        &[data-inlineheader='true'] {
            [data-site='materials-energy-expo'] & {
                padding-top: var(--spacing-medium);
                padding-bottom: var(--spacing-medium);
                display: grid;
                gap: var(--spacing-small);
                color: var(--colour-text-secondary);
                @media( min-width: 0px ) {
                    grid-template-columns: 1fr;
                }
                @media( min-width: 800px ) {
                    grid-template-columns: repeat(auto-fit,minmax(300px,1fr));
                }
                @media( min-width: 1100px ) {
                    grid-template-columns: repeat(auto-fit,minmax(450px,1.5fr));
                }
                > ul {
                    display: contents
                }
                h1 {
                    margin-bottom: var(--spacing-small);
                }
                p {
                    font-size: var(--font-size-large);
                }
            }
        }
        &[data-inlineheader='false'] {
            [data-site='events'] & {
                display: flex;
                flex-direction: column;
                position: relative;
                padding-bottom: var(--spacing-medium);
                > header {
                    margin-bottom: 0 !important;
                    padding-top: var(--spacing-small);
                    display: flex;
                    flex-direction: column;
                    p {
                        margin-top: 0;
                        padding-bottom: 0 !important;
                    }
                    > h1 {
                        width: fit-content;
                    }
                }
                > ul {
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr 1fr;
                    @media ( max-width: 850px ) {
                        grid-template-columns: 1fr 1fr;
                    }
                    @media ( max-width: 400px ) {
                        grid-template-columns: 1fr;
                    }                    
                    align-self: center;
                    margin-top: 0 !important;
                    padding-top: var(--spacing-small) !important;
                    width: 100%;
                    gap: 0;
                    text-align: center;
                }
            }
        }
    }

    &[data-type='Compact'],
    &[data-type='Light'] {

        > ul {

            display: grid;
            gap: var(--spacing-small);
            [data-site='materials-energy-expo'] & { gap: var(--spacing-medium); }
            grid-template-columns: repeat(auto-fit,minmax(var(--card-min-width),1fr));
            filter: var(--filter-shadow-mid);

            [data-site='events'] &{
                filter: none !important;
            }

            &[data-number='3'] {
                @media (min-width: 0px) {
                    grid-template-columns: 100%;
                }
                @media (min-width: 900px) {
                    grid-template-columns: 50% 50%;
                }
                @media (min-width: 900px ) and ( max-width: 1200px) {
                    > :last-child { grid-column: 1 / span 2; }
                }
                @media (min-width: 1200px) {
                    grid-template-columns: repeat(auto-fit,minmax(var(--card-min-width),1fr));
                }
            }

            &[data-number='4'] {
                @media (min-width: 0px) {
                    grid-template-columns: 100%;
                }
                @media (min-width: 900px) {
                    grid-template-columns: 50% 50%;
                }
                @media (min-width: 1300px) {
                    grid-template-columns: repeat(auto-fit,minmax(var(--card-min-width),1fr));
                }
            }

        }

    }

    &[data-type='Slideshow'],
    &[data-type='Testimonials'] {
        > div > ul {
            display: grid;
            grid-template-areas: "main";
            grid-template-columns: 1fr;
            grid-template-rows: 1fr;
            position: relative;
            justify-content: center;
            align-items: center;
            margin: 0;
            padding: 0;
            > li {
                grid-area: main;
                min-width: 100%;
                max-width: 100%;
                width: 100%;
                height: 100%;
                list-style-type: none;
                transition: filter var(--transition-time) linear;
                filter: opacity(0);
                &[data-current='true'] {
                    filter: opacity(1);
                    transition: filter var(--transition-time) linear;
                    z-index: 2;
                }
                h1:first-child { padding-right: 4em; }
                @media (max-width: 1000px) {
                    padding: 0 20px;
                }
            }
        }
    }

    &[data-type='Expanded'] {
        display: flex;
        flex-direction: column;
        padding: 0 !important;
        > header {
            padding: var(--spacing-medium) var(--page-padding-horizontal) 0 var(--page-padding-horizontal);
            > h1 {
                margin-bottom: 0;
                padding-bottom: 0;
            }
            [data-site='materials-energy-expo'] & {
                :last-child {
                    margin-bottom: 0;
                    padding-bottom: 0;
                }
                + ul > li:first-child:last-child > div {
                    margin-bottom: var(--spacing-medium);
                    > section {
                        padding-top: 0;
                        padding-bottom: 0;
                        align-content: flex-start;
                    }
                }
            }
        }
        > ul {
            flex-direction: column;
            min-width: 100%;
            > li {
                padding: var(--spacing-medium) var(--page-padding-horizontal);
                [data-site='materials-energy-expo'] & {
                    padding: 0 var(--page-padding-horizontal);
                }
                &:nth-child(odd) {
                    background: var(--colour-background-primary);
                }
                &:nth-child(even) {
                    background: var(--colour-background-secondary);
                    > div {
                        direction: rtl;
                        > * { direction: ltr; }
                    }
                }
            }
            [data-site='materials-energy-expo'] & {
                > li {
                    &:nth-child(odd) > div {
                        direction: rtl;
                        > * { direction: ltr }
                        img { border-bottom-left-radius: calc(2 * var(--spacing-medium)); }
                    }
                    &:nth-child(even) > div {
                        direction: ltr;
                        > * { direction: ltr; }
                        img { border-bottom-right-radius: calc(2 * var(--spacing-medium)); }
                    }
                }
            }
        }
    }

    &[data-type='IconsNoText'] {
        display: flex;
        flex-direction: column;
        position: relative;
        padding-bottom: var(--spacing-medium);
        > header {
            margin-bottom: 0 !important;
            padding: var(--spacing-small) 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            > ::before {
                left: 1.4em !important;
            }
            > h1 + p {
                margin-top: 0;
                font-size: var(--font-size-large);
            }
        }
        > ul {

            display: grid;
            gap: var(--spacing-small);
            grid-template-columns: repeat(auto-fit,minmax(250px,1fr));

            &[data-number='4'] {
                @media (min-width: 0px) {
                    grid-template-columns: 50% 50%;
                }
                @media (max-width: 500px) {
                    gap: var(--spacing-small-half)
                }
                @media (min-width: 1200px) {
                    grid-template-columns: repeat(4, 25%);
                }
            }
        }
    }

}

.Slideshow {

    position: relative;

    > button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 2.5em;
        height: 2.5em;
        padding: 0.5em;
        border: 2px solid var(--colour-text-accent-primary);
        border-radius: 100%;
        background: transparent;
        svg {
            fill: var(--colour-text-accent-primary);
            width: 1.5em;
        }
    }

    [data-type='Testimonials'] & {
        button {
            top: calc( 50% - 1.25em );
        }
    }

    > .ControlLeft {
        position: absolute;
        [data-type='Slideshow'] & {
            @media( min-width: 0px ) {
                left: 0.5em;
                filter: var(--filter-shadow-mid);
            }
            @media( min-width: 1000px ) {
                left: -3.5em;
                filter: none;
            }
        }
        [data-type='Testimonials'] & {
            left: calc( -1 * var(--page-padding-horizontal) + 0.5em );
        }
    }

    > .ControlRight {
        position: absolute;
        [data-type='Slideshow'] & {
            @media( min-width: 0px ) {
                right: 0.5em;
                filter: var(--filter-shadow-mid);
            }
            @media( min-width: 682px ) {
                right: var(--spacing-small);
            }
            @media( min-width: 1000px ) {
                right: -3.5em;
                filter: none;
            }
        }
        [data-type='Testimonials'] & {
            right: calc( -1 * var(--page-padding-horizontal) + 0.5em );
        }
    }

    > .ControlLeft,
    > .ControlRight {
       top: 0.5em;
       backdrop-filter: blur(4px);
    }

    .ControlLeft {
        svg{
            transform: rotate(180deg);
        }
    }
}

[data-site='materials-energy-expo'] [data-background='MEE_Colour_1'] +
    .ContentBlockList[data-type='Compact']:not([data-background='MEE_Colour_1']) { padding-top: 0; }

[data-site='materials-energy-expo'] [data-background='MEE_Colour_2'] +
    .ContentBlockList[data-type='Compact']:not([data-background='MEE_Colour_2']) { padding-top: 0; }

[data-site='materials-energy-expo'] [data-background='MEE_Colour_3'] +
    .ContentBlockList[data-type='Compact']:not([data-background='MEE_Colour_3']) { padding-top: 0; }

[data-site='materials-energy-expo'] [data-background='MEE_Colour_5'] +
    .ContentBlockList[data-type='Compact']:not([data-background='MEE_Colour_5']) { padding-top: 0; }

[data-site='plastics-world-expos-na']
    .ContentBlockList[data-type='Testimonials']{ 
        padding: var(--spacing-medium) 
        var(--page-padding-horizontal) 
        var(--spacing-small-half) 
        var(--page-padding-horizontal);
}

.SlideShowMultiple{ 
    display: flex;
    list-style: none; 
    padding: 0;
    margin: 0;

    .ControlLeft {
        svg{
            transform: rotate(180deg);
        }
    }

    .SlideShowMultipleInner {
        flex: 1 0 calc(98% / 3); 
        display: flex;
        align-items: center; 
        justify-content: center;
        box-sizing: border-box; 

        @media( max-width: 1000px ) {
            flex: 1 0 calc(96% / 2); 
        }
        @media( max-width: 750px ) {
            flex: 1 0 calc(96% / 1); 
        }
    }
     
    .SlideShowMultipleInner:nth-of-type(1),
    .SlideShowMultipleInner:nth-of-type(3){
        h1{
            font-size: var(--font-size-base);
        }
        h2{
            font-size: var(--font-size-large);
        }
    }

    .SlideShowMultipleInner:nth-of-type(2) {
        @media( max-width: 750px ) {
            display:none;
        }
    }

    .SlideShowMultipleInner:nth-of-type(3) {
        @media( max-width: 1000px ) {
            display:none;
        }
    }
  
    button{
        height: calc(1.1 * var(--spacing-small));
        max-height: calc(1.1 * var(--spacing-small));
        border-radius: calc(1.1 * var(--spacing-small));
        margin-top: var(--spacing-medium);

        svg{
            height:10px;
        } 

        &:hover{    
            background-color: var(--colour-background-secondary);        
        }
    }
}

   
[data-type='Testimonials']  {
    .SlideShowMultipleInner  {
        display:block;

        div::before {
           display:none;
        }
        div {
            display: block!important;


        }
         div::after {
            display:none;
        }
    }
}
    